<template>
  <div class="w-full">
    <div v-if="showPackInfo" class="pack-item flex justify-between items-center py-2">
      <h2 class="pack-title text-left text-brown font-medium md:text-left text-xl md:mb-0 w-4/5">{{ productItem.product.name }} - {{ pack.name }}</h2>
      <button class="focus:outline-none" @click="displayPayment">
        <span class="opacity-60 mr-2">{{ statusText }}</span>
        <img class="btn-ico-triangle transform opacity-100" :class="{ 'rotate-180': displaying }" src="@/assets/img/ui/ui_triangle_down.svg" />
      </button>
    </div>
    <transition name="slide">
      <div v-if="displaying" class="flex w-full flex-col rounded">
        <!-- Mobile -->
        <section class="block md:hidden">
          <div class="pb-2 spec-item bg-gray-100 px-2" v-for="(spec, specIdx) in pack.specs" :key="specIdx">
            <p class="mb-0 opacity-60">商品</p>
            <p class="mb-0">{{ productItem.product.name }}</p>
            <span class="mr-3"><span class="opacity-60">規格 </span>{{ spec.spec == '' ? '#' : spec.spec }}</span>
            <span class="mr-3"><span class="opacity-60">數量 </span>{{ spec.quantity }}</span>
            <span><span class="opacity-60">金額 </span>${{ spec.price | currency }}</span>
          </div>
        </section>
        <!-- PC -->
        <section class="hidden md:block">
          <div class="table table-pack">
            <div class="table-row">
              <div class="table-cell pr-4 opacity-60">商品</div>
              <div class="table-cell pr-4 opacity-60">規格</div>
              <div class="table-cell pr-4 opacity-60">數量</div>
              <div class="table-cell opacity-60">金額</div>
            </div>
            <div class="table-row bg-gray-100" v-for="(spec, specIdx) in pack.specs" :key="specIdx">
              <div class="table-cell py-1 pr-4 pl-1">{{ productItem.product.name }}</div>
              <div class="table-cell py-1 pr-4">{{ spec.spec == '' ? '#' : spec.spec }}</div>
              <div class="table-cell py-1 pr-4">{{ spec.quantity }}</div>
              <div class="table-cell py-1 pr-1">${{ spec.price | currency }}</div>
            </div>
          </div>
        </section>
        <order-progress :status="pack.status" :delivery="pack.specs[0].delivery"/>
      </div>
    </transition>
  </div>
</template>

<script>
import OrderProgress from './OrderProgress.vue'

export default {
  props: {
    showPackInfo: {
      type: Boolean,
      default: true
    },
    pack: {},
    productItem: {}
  },
  components: {
    OrderProgress,
  },
  data() {
    return {
      displaying: true
    }
  },
  computed: {
    statusText() {
      if (this.displaying) {
        return ''
      }
      return ''
    }
  },
  methods: {
    displayPayment() {
      this.displaying = !this.displaying
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-ico-triangle {
  width: 12px;
  height: 12px;
  padding: 0;
  margin: 0 1px 2px;
}

.table-pack {
  border-spacing: 0 5px;
}

.pack-title {
  position: relative;
}

.pack-title::before {
  content: '●';
  position: absolute;
  right: 100.07%;
  opacity: .7;
  line-height: 1.5rem
}

.pack-item {
  border-bottom: 1px dashed #00000060;
}

.spec-item:not(:last-child) {
  // border-bottom: 1px dashed #00000060;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>