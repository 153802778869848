<template>
  <div class="progress-wrapper flex w-full flex-col">
    <div class="mt-4 px-4 step-wrapper flex w-full">
      <div class="step flex" v-for="(item, index) in list" :key="index">
        <div class="ball-wrapper">
          <div :class="{ active: step >= item.status }" class="ball flex justify-center items-center mb-4">
            <font-awesome-icon icon="chevron-right" />
          </div>
          <div class="status-text">
            {{ item.text }}
          </div>
        </div>
        <div v-show="index !== list.length - 1" :class="{ active: step > item.status }" class="bar"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    step: {
      type: Number,
      default: 10,
    }
  },
  data() {
    return {
      list: [
        { text: '請求退貨', status: 10 },
        { text: '退款中', status: 11 },
        { text: '退款完成', status: 12 },
      ],
    }
  },
}
</script>


<style lang="scss" scoped>
.progress-wrapper {
  .step-wrapper {
    padding: 0 8px;
    .step {
      width: 30%;
      &:last-child {
        width: auto;
      }
    }
    .ball-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      margin-bottom: 50px;
      .ball {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ddd;
        color: #fff;
        &.active {
          background-color: rgb(203, 93, 125);
        }
      }
      .status-text {
        position: absolute;
        width: 100px;
        text-align: center;
        top: 40px;
        // left: -50%;
        // transform: translateX(25%);
        .status-date {
          transform: scale(0.8);
          font-size: 10px;
        }
      }
    }
    .bar {
      width: 70%;
      height: 3px;
      background-color: #ddd;
      margin: 0 auto;
      margin-top: 14px;
      &.active {
        background-color: rgb(203, 93, 125);
      }
    }
  }
}
</style>