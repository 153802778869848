<template>
  <div class="w-full">
    <div v-if="showPaymentInfo" class="flex justify-end" :class="{'mb-1':displaying}">
      <button class="focus:outline-none mb-2" @click="displayPayment">
        <span class="opacity-60 mr-2">{{ statusText }}</span>
        <img class="btn-ico-triangle transform opacity-100" :class="{ 'rotate-180': displaying }" src="@/assets/img/ui/ui_triangle_down.svg" />
      </button>
    </div>
    <transition name="slide"> 
      <div v-if="displaying" class="flex w-full flex-col bg-gray-50 px-5 pb-4 rounded">
        <div class="flex justify-between border-b border-gray-200 p-2 pt-5">
          <div>銀行代碼</div>
          <div>{{ payment.bank }}</div>
        </div>
        <div class="flex justify-between border-b border-gray-200 p-2">
          <div>繳費帳號</div>
          <div>{{ payment.account }}</div>
        </div>
        <div class="flex justify-between border-b border-gray-200 p-2">
          <div>繳費金額</div>
          <div>{{ payment.amount }}</div>
        </div>
        <div class="flex justify-between p-2">
          <div>繳費期限</div>
          <div>{{ payment.deadline }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    showPaymentInfo:{
      type: Boolean,
      default: true
    },
    payment: {
      type: Object,
      default: ()=> ({
        bank: '',
        account: '',
        amount: '',
        deadline: ''
      })
    }
  },
  data() {
    return {
      displaying: false
    }
  },
  computed: {
    statusText() {
      if (this.displaying) {
        return '隱藏付款資訊'
      }
      return '顯示付款資訊'
    }
  },
  methods: {
    displayPayment() {
      this.displaying = !this.displaying
    }
  }
}
</script>

<style lang="scss" scoped>

.btn-ico-triangle{
  width:12px;
  height:12px;
  padding:0;
  margin: 0 1px 2px;
}
</style>