<template>
  <div class="w-full">
    <div v-if="showReceiverInfo" class="flex justify-start" :class="{'mb-1':displaying}">
      <button class="focus:outline-none mb-2" @click="displayReceiver">
        <span class="opacity-60 mr-2">{{ statusText }}</span>
        <img class="btn-ico-triangle transform opacity-100" :class="{ 'rotate-180': displaying }" src="@/assets/img/ui/ui_triangle_down.svg" />
      </button>
    </div>
    <transition name="slide"> 
      <div v-if="displaying" class="flex w-full flex-col bg-gray-50 px-5 pb-4 rounded">
        <div class="flex justify-between border-b border-gray-200 p-2 pt-5">
          <div>收件人姓名</div>
          <div>{{ receiver.name }}</div>
        </div>
        <div class="flex justify-between border-b border-gray-200 p-2">
          <div>收件人電話</div>
          <div>{{ receiver.phone }}</div>
        </div>
        <div class="flex justify-between p-2">
          <div>收件人地址</div>
          <div>{{ receiver.address }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    showReceiverInfo:{
      type: Boolean,
      default: true
    },
    receiver: {
      type: Object,
      default: ()=> ({
        name: '',
        phone: '',
        address: ''
      })
    }
  },
  data() {
    return {
      displaying: false
    }
  },
  computed: {
    statusText() {
      if (this.displaying) {
        return '隱藏收件人資訊'
      }
      return '顯示收件人資訊'
    }
  },
  methods: {
    displayReceiver() {
      this.displaying = !this.displaying
    }
  }
}
</script>

<style lang="scss" scoped>

.btn-ico-triangle{
  width: 12px;
  height: 12px;
  padding:0;
  margin: 0 1px 2px;
}
</style>