<template>
  <div class="progress-wrapper flex w-full flex-col">
    <div class="mt-4 px-4 step-wrapper flex w-full">
      <div class="step flex" v-for="(item, index) in list" :key="index">
        <div class="ball-wrapper">
          <div :class="{ active: delivery.step >= item.status }" class="ball flex justify-center items-center mb-4">
            <font-awesome-icon icon="chevron-right" />
          </div>
          <div class="status-text">
            {{ item.text }}
            <div class="status-date">
              {{ currentStatusDate(item.status) }}
            </div>
          </div>
        </div>
        <div v-show="index !== list.length - 1" :class="{ active: delivery.step > item.status }" class="bar"></div>
      </div>
    </div>
    <div v-if="delivery.provider" class="w-full flex justify-between mt-2">
      <div>{{ delivery.provider }}</div>
      <div>
        <a :href="delivery.url" rel="noopener noreferrer" target="_blank">
          {{ delivery.tracking }}
        </a>
        <button v-clipboard:copy="delivery.tracking" v-clipboard:success="handleCopied">
          <font-awesome-icon icon="clone" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import message from 'ant-design-vue/es/message'

export default {
  props: {
    delivery: {
      type: Object,
      default: () => ({
        step: 0,
        provider: '',
        tracking: '',
        url: '',
        type: '',
        steps: {
          started_at: null,
          stocked_at: null,
          shipped_at: null,
          stopped_at: null
        }
      })
    }
  },
  data() {
    return {
      list: [
        { text: '處理中', status: 0 },
        { text: '理貨中', status: 1 },
        { text: '出貨中', status: 2 },
        { text: '已配達', status: 3 }
      ]
    }
  },
  methods: {
    currentStatusDate(status) {
      const {
        steps: { started_at, stocked_at, shipped_at, stopped_at }
      } = this.delivery
      switch (status) {
        case 0:
          return started_at
        case 1:
          return stocked_at
        case 2:
          return shipped_at
        case 3:
          return stopped_at
        default:
          return started_at
      }
    }
  },
  handleCopied() {
    message.success('已複製')
  }
}
</script>


<style lang="scss" scoped>
.progress-wrapper {
  .step-wrapper {
    padding: 0 8px;
    .step {
      width: 30%;
      &:last-child {
        width: auto;
      }
    }
    .ball-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      margin-bottom: 50px;
      .ball {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ddd;
        color: #fff;
        &.active {
          background-color: rgb(203, 93, 125);
        }
      }
      .status-text {
        position: absolute;
        width: 100px;
        text-align: center;
        top: 40px;
        // left: -50%;
        // transform: translateX(25%);
        .status-date {
          transform: scale(0.8);
          font-size: 10px;
        }
      }
    }
    .bar {
      width: 70%;
      height: 3px;
      background-color: #ddd;
      margin: 0 auto;
      margin-top: 14px;
      &.active {
        background-color: rgb(203, 93, 125);
      }
    }
  }
}
</style>