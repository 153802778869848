<template>
  <article class="h-inherit pt-12 md:h-auto md:pt-0 md:max-w-1200 md:mx-auto">
    <div class="bg-gray-100 md:bg-white min-h-screen h-min-full rounded-t-3xl">
      <div class="container-wrapper md:px-0">
        <h1 class="text-xl md:text-3xl text-brown my-2 md:mb-8">購物紀錄</h1>
        <template v-if="orders.length > 0">
          <orderItem v-for="(item, index) in orders" :item="item" :key="index"></orderItem>
        </template>
      </div>
    </div>
  </article>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import OrderItem from '@/views/Orders/OrderItem.vue'

export default {
  name: 'Orders',
  components: {
    OrderItem
  },
  data() {
    return {
      loading:false,
      form: {
        page: 0, // integer 第幾頁
        // size: '', // integer 筆數
        // status: 0, //integer 狀態
      },
    }
  },

  methods: {
    ...mapActions(['getOrders', 'cleanOrders', 'getUser']),
    async scrollBottomGetData() {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop //windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight //scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight //滚动条到底部的条件
      if (scrollTop + windowHeight >= scrollHeight) {
        // *api 取得資料
        if (this.loading == false) {
          this.loading = true
          this.form.page += 1
          await this.getOrders(this.form)
          this.loading = false
        }
      }
    },
  },
  computed: {
    ...mapGetters(['orders','orderMinusOnePage','orderZeroPage']),
    // fakeOrders() {
    //   if (this.orders.length > 0) {
    //       return this.orders.map(item => ({
    //         ...item,
    //         status: 13,
    //         packs: [...item.packs.map(pack => ({...pack, status: 13, delivery: {...pack.delivery, step: 11} }))],
    //       }))
    //   }
    //   return []
    // }
  },
  watch:{
    'orderMinusOnePage': {
      handler: function() {
        if(this.form.page >= 1){
          this.form.page -= 1
          console.log('orderMinusOnePage 減少1page後的結果:', this.form.page)
          this.loading = false
        }
      },
      immediate: true
    },
    'orderZeroPage': {
      handler: function() {
        window.scrollTo(0,0)
        this.form.page = 0
        console.log('orderZeroPage')
        this.loading = false
      },
      immediate: true
    }
  },
  async mounted() {
    window.addEventListener('scroll', this.scrollBottomGetData, true)
    await this.cleanOrders()
    await this.getOrders()
    await this.getUser()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollBottomGetData, true)
  }
}
</script>
